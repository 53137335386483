.tabTitle {
    text-align: center;
    margin: 0;
    margin-top: .75em;
    font-size: 1.75em;
    font-family: "Gotham - Medium";
}

.statTitle {
    text-align: center;
    margin: .33em 0 .75em 0;
    font-size: 1em;
    color: #444444;
}