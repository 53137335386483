.rankingsNameDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 5vh;
    margin-right: 1.5vw;
    margin-bottom: 1.25vw;
}

.rankingsNameDiv p {
    margin: 0;
    text-align: right;
    cursor: text;
}

.rankingsNameDiv a {
    font-size: .9em;
    color: black;
    text-decoration: underline solid transparent;
    transition: text-decoration .3s;
}
.rankingsNameDiv a.noLink, .rankingsNameDiv a.noLink:hover {
    pointer-events: none;
}
.rankingsNameDiv a:hover {
    text-decoration: underline solid black;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    .rankingsNameDiv a {
        font-size: .85em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    .rankingsNameDiv a {
        font-size: .8em;
    }
}