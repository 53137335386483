#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 96vw;
    height: fit-content;
    padding: 2vw;
    background-color: #333;
}

#footer p {
    margin: .75vh;
    color: white;
    font-size: .9em;
}
#footer p:nth-child(2) {
    color: #e0e0e0;
}