#rankingsWrapper {
    width: 90vw;
    max-width: 1111px;
    margin: 0 auto;
    padding: 0 5vw 2.5vw 5vw;
}

#rankingsWrapperInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 2.5vh;
}

#rankingsHeader {
    text-align: center;
    font-size: 1.1em;
    margin: 0;
}

#rankingsSwitchWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: .85em;
}

#rankingsSwitchHeaderLeft {
    text-align: right;
    flex: 1;
}

label.rankingsSwitch {
    margin: 0 -.5vw;
    transform: scale(.66) translate(0px, 6px);
}

#rankingsSwitchHeaderRight {
    text-align: left;
    flex: 1;
}

#rankingsNames {
    display: flex;
    flex-direction: column;
    justify-content: right;
    width: fit-content;
    /* width: 20vw; */
    height: 100%;
}

#rankingsBars {
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex: 1;
    /* width: 68vw; */
    height: 100%;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    #rankingsWrapper {
        width: 95vw;
        padding: 0 2.5vw 2.5vw 2.5vw;
    }

    #rankingsWrapperInner {
        margin-left: -3vw;
    }

    #rankingsSwitchHeaderLeft, #rankingsSwitchHeaderRight {
        font-size: .85em;
    }

    #rankingsNames {
        width: 33vw;
    }
    
    #rankingsBars {
        width: 58vw;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    #rankingsWrapper {
        width: 100vw;
        padding: 0 0 2.5vw 0;
    }
}


/* https://www.htmllion.com/css3-toggle-switch-button.html */
.rankingsSwitch {
	position: relative;
	display: block;
	/* vertical-align: top; */
	width: 100px;
	height: 30px;
	padding: 3px;
	margin: 0 10px 10px 0;
	/* background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px); */
	border-radius: 18px;
	/* box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05); */
	cursor: pointer;
	box-sizing:content-box;
}
.rankingsSwitch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.rankingsSwitch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing:content-box;
}
/* .rankingsSwitch-label:before, .rankingsSwitch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.rankingsSwitch-label:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.rankingsSwitch-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
} */
.rankingsSwitch-input:checked ~ .rankingsSwitch-label {
	background: #00AFF0;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.rankingsSwitch-input:checked ~ .rankingsSwitch-label:before {
	opacity: 0;
}
.rankingsSwitch-input:checked ~ .rankingsSwitch-label:after {
	opacity: 1;
}
.rankingsSwitch-handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 28px;
	height: 28px;
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.rankingsSwitch-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.rankingsSwitch-input:checked ~ .rankingsSwitch-handle {
	left: 74px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
 
/* Transition
========================== */
.rankingsSwitch-label, .rankingsSwitch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}