#tabsWrapper .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: 88vw;
    margin: auto;
    padding: 0 1vw;
    /* overflow: unset; */
    /* overflow-x: auto; */
    /* overflow: unset; */
    /* overflow-y: auto; */
}

#tabsWrapper .react-tabs__tab {
    min-width: min-content;
}

#tabsWrapper .react-tabs__tab a {
    word-break: break-word;
    font-size: .9em;
    color: #444444;
    text-decoration: underline solid transparent;
    transition: text-decoration .3s;
}
#tabsWrapper .react-tabs__tab a:hover {
    text-decoration: underline solid #444444;
}
#tabsWrapper .react-tabs__tab--selected a {
    color: #00AFF0;
}
#tabsWrapper .react-tabs__tab--selected a:hover {
    text-decoration: underline solid #00AFF0;
}

/* #tabsWrapper .react-tabs__tab-panel {

}
#tabsWrapper .react-tabs__tab-panel--selected {

} */


#selectDropdown {
    display: none;

    width: fit-content;
    max-width: 88vw;
    margin: auto;
    padding: 1vw;
}


@media only screen and (min-width: 0px) and (max-width: 750px) {
    .desktop {
        display: none;
    }

    #selectDropdown {
        display: flex;
    }
}

@media only screen and (min-width: 0px) and (max-width: 450px) {
    #selectDropdown {
        margin-top: .75em;
    }
}