#header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100vw;
    height: 15vh;
    background-color: #00AFF0;
}

#homeLinkRefreshButtonDiv {
    display: none;
}

#header #homeLink {
    position: absolute;
    left: 3.5vh;
    padding: 8px 12px;
    font-size: .9em;
    color: white;
    cursor: pointer;
}

#header #homeIcon {
    height: 5vh;
    font-size: 2.75em;
    transition: opacity .1s;
}
#header #homeIcon:hover {
    opacity: .85;
}

#header #refreshButton {
    position: absolute;
    right: 3.5vh;
    padding: 8px 12px;
    font-size: .9em;
    color: #00AFF0;
    background-color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: box-shadow .1s;
}
#header #refreshButton:hover {
    box-shadow: 0 2px 8px hsla(0, 0%, 4%, .2), 0 0 0 1px hsla(0, 0%, 4%, .075);
}
#header #refreshButton:disabled {
    opacity: .5;
}

#header #refreshButtonEstimatedTime {
    position: absolute;
    right: 3.5vh;
    margin-top: 4vh;
}

#header h1 {
    margin: 0;
    color: white;
    font-size: 2em;
}

#header p {
    margin: 0;
    margin-top: .75vh;
    color: #e0e0e0;
    font-size: .9em;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    #header {
        justify-content: start;
        width: 92vw;
        height: fit-content;
        padding: 2vh 4vw;
    }

    #homeLinkRefreshButtonDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -2vh;
        padding: .5em 0;
    }

    #header #homeLink.desktop, #header #refreshButton.desktop, #header #refreshButtonEstimatedTime.desktop {
        display: none;
    }

    #refreshButtonDiv {
        display: flex;
        flex-direction: column;
        text-align: right;
    }

    #header #refreshButton {
        position: unset;
        /* right: 4vw;
        bottom: 4.25vh; */
        padding: 6px 10px;
        font-size: .85em;
    }

    #header #refreshButtonEstimatedTime {
        position: unset;
        /* right: 4vw;
        bottom: 1.25vh; */
        margin-top: .5vh;
        font-size: .85em;
    }

    #header #homeLink {
        position: unset;
        left: 0;
        bottom: 1.5vh;
        padding: 0;
    }

    #header h1 {
        font-size: 1em;
    }

    #header p {
        font-size: .85em;
    }
}