#progressBar {
    position: absolute;
    top: 15vh;
    width: 0%;
    height: 1vh;
    /* margin-top: 15vh;
    margin-bottom: -1vh; */
    background-color: #00ff00;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    #progressBar {
        top: 19vh;
        left: 0;
    }
}