.memberTile {
    display: flex;
    flex-direction: column;
    width: 25vw;
    max-width: 250px;
    margin: 1.5vw;
    padding: 1vw;

    background-color: white;
    box-shadow: 0 2px 5px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .075);
}

.memberImage {
    width: 27vw;
    max-width: calc(250px + 2vw);
    height: 27vw;
    max-height: calc(250px + 2vw);
    margin: -1vw;
    margin-bottom: .75vh;
    /* background: url("https://i.groupme.com/1930x1448.jpeg.0fa04668183e4298ba995ddbcad3a3ea"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 2px 5px hsla(0, 0%, 4%, .075), 0 0 0 1px hsla(0, 0%, 4%, .025);
}

.memberTile a {
    margin: 1.25vh;
    text-align: left;
    font-size: 1.5em;
    color: black;
    font-family: "Gotham - Medium";
    text-decoration: underline solid transparent;
    transition: text-decoration .3s;
}
.memberTile a:hover {
    text-decoration: underline solid black;
}

.memberTile p {
    margin: 0;
    margin-left: 1.25vh;
    margin-bottom: 1vh;
    font-size: 1em;
    color: #444444;
}


@media only screen and (min-width: 0px) and (max-width: 1000px) {
    .memberTile a {
        font-size: 1.45em;
    }

    .memberTile p {
        font-size: 1.05em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
    .memberTile a {
        font-size: 1.35em;
    }

    .memberTile p {
        font-size: .95em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 700px) {
    .memberTile a {
        font-size: 1.25em;
    }

    .memberTile p {
        font-size: .85em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 450px) {
    .memberTile {
        width: 28vw;
    }

    .memberImage {
        width: 30vw;
        height: 30vw;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    .memberTile a {
        font-size: 1.1em;
    }
}