#userInfoWrapper {
    width: 90vw;
    margin: 0;
    padding: 2vw 5vw;
}

#userInfoWrapperInner {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    align-items: center;
    /* width: min-content; */
    width: fit-content;
    /* height: 15vw;
    max-height: 175px;
    min-height: 75px; */
    margin: auto;
    padding: 1vw;
    background-color: white;
    box-shadow: 0 2px 5px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .075);
}

.userPicture {
    width: 15vw;
    max-width: 175px;
    min-width: 75px;
    height: 15vw;
    max-height: 175px;
    min-height: 75px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 2px 5px hsla(0, 0%, 4%, .075), 0 0 0 1px hsla(0, 0%, 4%, .025);
}

.userName {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 44vw;
}

.userName h1 {
    margin: .5vh 3vh;
    font-size: 2em;
    font-family: "Gotham - Medium";
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    #userInfoWrapper {
        margin-top: 0;
    }

    .userName {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 75vw;
    }

    .userName h1 {
        font-size: 1.25em;
    }
}