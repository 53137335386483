* {
  /* transition: all .3s; */

  font-family: "Gotham - Book";
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: 'Gotham - Light';
  src: url('./fonts/Gotham-Light.otf');
} */

@font-face {
  font-family: 'Gotham - Book';
  src: url('./fonts/Gotham-Book.otf');
}

@font-face {
  font-family: 'Gotham - Medium';
  src: url('./fonts/Gotham-Medium.otf');
}