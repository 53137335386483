.chatTile {
    display: flex;
    flex-direction: column;
    width: 18.75vw;
    max-width: 225px;
    margin: 1.5vw;
    padding: 1vw;

    background-color: white;
    box-shadow: 0 2px 5px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .075);
}

.chatImage {
    width: 17.75vw;
    max-width: calc(225px - 1vw);
    height: 17.75vw;
    max-height: calc(225px - 1vw);
    margin: .5vw;
    /* background: url("https://i.groupme.com/1930x1448.jpeg.0fa04668183e4298ba995ddbcad3a3ea"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    box-shadow: 0 2px 5px hsla(0, 0%, 4%, .075), 0 0 0 1px hsla(0, 0%, 4%, .025);
    transition: box-shadow .1s;
}
.chatImage:hover {
    box-shadow: 0 2px 8px hsla(0, 0%, 4%, .2), 0 0 0 1px hsla(0, 0%, 4%, .075);
}

.chatTile .chatTitle {
    margin-top: 1vh;
    margin-bottom: 1vh;
    text-align: center;
    font-size: 1.5em;
    color: black;
    font-family: "Gotham - Medium";
    text-decoration: underline solid transparent;
    transition: text-decoration .3s;
}

.chatTile .chatTitle:hover {
    text-decoration: underline solid black;
}

.chatInfoWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
}

.chatInfoWrapperInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.chatInfoWrapperLeft {
    width: fit-content;
    margin-right: 1vw;
}

.chatInfoWrapperRight {
    width: fit-content;
    margin-left: -3vw;
    text-align: right;
}

.chatTile p {
    margin: 0;
    margin-top: 1vh;
    font-size: 1.1em;
    color: black;
}


@media only screen and (min-width: 0px) and (max-width: 1000px) {
    .chatTile a {
        font-size: 1.45em;
    }

    .chatTile p {
        font-size: 1.05em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
    .chatTile a {
        font-size: 1.35em;
    }

    .chatTile p {
        font-size: .95em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 700px) {
    .chatTile a {
        font-size: 1.25em;
    }

    .chatTile p {
        font-size: .85em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 450px) {
    .chatTile {
        width: 38.5vw;
        padding: 3vw;
    }

    .chatImage {
        width: 37.5vw;
        height: 37.5vw;
    }

    .chatTile p {
        font-size: 1em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 400px) {
    .chatTile .chatTitle {
        font-size: 1.25em;
    }

    .chatTile p {
        font-size: .9em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    .chatTile p {
        font-size: .85em;
    }
}