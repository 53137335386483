.rankingsBarDiv {
    width: 100%;
    min-width: fit-content;
    height: 5vh;
    line-height: 5vh;
    margin-bottom: 1.25vw;
    padding: 0 1.5vh;
    font-size: .9em;
    color: white;
    background-color: #00AFF0;
}