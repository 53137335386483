#overallStatisticsWrapper {
    width: 90vw;
    margin: 0;
    padding: 1.5vw 5vw 0 5vw;
}
#overallStatisticsWrapper.chat {
    padding: 1.5vw 5vw;
}

#overallStatisticsWrapperInner {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(800px + 12vw);
    height: 100%;
    margin: auto;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    #overallStatisticsWrapper, #overallStatisticsWrapper.chat {
        width: 95vw;
        padding: 1.5vw 2.5vw 0 2.5vw;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    #overallStatisticsWrapper, #overallStatisticsWrapper.chat {
        width: 100vw;
        padding: 1.5vw 0 0 0;
    }
}