#chatTileWrapper {
    width: 95vw;
    margin: 0;
    padding: 2.5vw;
}

#chatTileWrapperInner {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    #chatTileWrapper {
        margin-top: 0;
    }
}