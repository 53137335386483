.overallStatistic {
    width: 19.5vw;
    max-width: 200px;
    margin: 1.5vw;
    text-align: center;
}

.overallStatistic h1 {
    margin: 0;
    word-break: break-word;
    font-size: .95em;
    color: #444444;
}

.overallStatistic p {
    margin: 0;
    margin-top: 1vh;
    font-family: "Gotham - Medium";
    font-size: 1.75em;
}


@media only screen and (min-width: 0px) and (max-width: 450px) {
    .overallStatistic {
        width: 20.75vw;
    }

    .overallStatistic h1 {
        font-size: .85em;
    }

    .overallStatistic p {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    .overallStatistic {
        width: 22vw;
    }

    .overallStatistic h1 {
        font-size: .75em;
    }

    .overallStatistic p {
        font-size: 1.25em;
    }
}